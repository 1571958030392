<template>

  <div class="lit">

    <header-lit-container name="Александр Сергеевич Пушкин"
                            v-bind:is-transition="true"></header-lit-container>

    <!--<header-lit-container-mini title="Александр Сергеевич Пушкин"
                            v-bind:is-transition="true"></header-lit-container-mini>


    <lit-section-grid-first></lit-section-grid-first>

    <lit-section-grid-second></lit-section-grid-second>
    -->

    <div id="lit_main_wrapper">
      <transition name="slideLitMain" appear>

        <ul id="lit_main">

          <li v-for="item in litItems" :key="item">
            <router-link :to="{ name: 'LitID', params: { id: item.itemID}}">
              <transition name="d_u_move" appear>
              <div class="lit_main_item">
                <img v-bind:src="item.photo" alt="фото">
                <div class="lit_main_item_title">{{ item.short_title }}</div>
              </div>
              </transition>
            </router-link>
          </li>

        </ul>
      </transition>
    </div>

  </div>
</template>

<script>

//import headerLitContainerMini from '@/components/headerLitContainerMini.vue'
//import headerTasksContainer from "@/components/headerTasksContainer.vue";
import HeaderLitContainer from "@/components/headerLitContainer.vue";
//import litSectionGridFirst from "@/components/litSectionGridFirst.vue";
//import litSectionGridSecond from "@/components/litSectionGridSecond.vue";

export default {
  name: "Lit",
  components: {
    HeaderLitContainer,
    //headerTasksContainer,
    //litSectionGridFirst,
    //litSectionGridSecond,
    //headerLitContainerMini
  },
  data() {
    return {
      numEl: 0
    }
  },
  computed: {
    litItems: function () {
      return this.$store.getters.getLitItems
    }
  },
  methods: {

  },
  mounted() {

    const sections = document.querySelectorAll('.lit_main_item');

    let options = {
      rootMargin: "0px",
      threshold: 0.5
    }

    var observer = new IntersectionObserver(entries => {
      entries.forEach((entry) => {
        const { target } = entry;
        if (entry.intersectionRatio < 0.5) {
          target.classList.remove("is-visible");
          //target.classList.add("is-grid");
          //target.classList.remove("rockets");
        } else {
          target.classList.add("is-visible");
          //target.classList.add("is-grid");
          //target.classList.remove("is-grid");
          //target.classList.add("d_u_move-enter-from");

          //target.classList.add("rockets");
        }
      })
    }, options);

    sections.forEach((section) => {
      observer.observe(section);
    })
  },
  created() {
  }
}

</script>

<style scoped>

.lit {
  width:                  100%;
  max-width:              100%;
  background-color:       #fdf5e6;
  margin-top:             var(--header-height);
}

#lit_main_wrapper {
  display:                grid;
  grid-template-columns:  1fr;
  max-width:              var(--width-wrapper-small);
  min-height:             100vh;
  place-content:          center;
  place-items:            center;
  padding-left:           .5rem;
  padding-right:          .5rem;
  margin:                 0rem auto 1rem auto;
}

@media (min-width: 60rem) {
  #lit_main_wrapper {
    max-width:              var(--width-wrapper-middle);
  }
}
@media (min-width: 75rem) {
  #lit_main_wrapper {
    max-width:              var(--width-wrapper-large);
  }
}

#lit_main {
  display: 			    grid;
  grid-template-columns:  repeat(2, minmax(min(45%, 14rem), 1fr));
  grid-gap:               1rem;
  max-width: var(--width-wrapper-small);
  min-width: 100%;
  align-content: end;
  margin-top: 1.5rem;
}

.lit_main_item {
  display:                grid;
  grid-template-rows:     calc(50vw - 1.5rem) auto;
  opacity: 0;
  transition:             opacity 400ms;
}

.lit_main_item > img {
  width:                  100%;
  height:                 100%;
  object-fit:             cover;
  object-position:        top;
  border-radius:          22px;
}

.lit_main_item_title {
  display:                 flex;
  align-items:             center;
  justify-content:         center;
  margin-top:              -3rem;
  height:                  3rem;
  background:              rgba(162, 162, 162, .5);
  backdrop-filter:         blur(5px);
  color:                   #eee;
  font-size:               var(--font-size-x-small);
  border-bottom-left-radius: 22px;
  border-bottom-right-radius: 22px;
  padding-left: 4px;
}

@media (min-width: 36rem) {
  .lit_main_item_title {
    font-size:               var(--font-size-middle);
  }
}

@media (min-width: 60rem) {
  #lit_main {
    grid-template-columns:  repeat(3, minmax(min(100%, 14rem), 1fr));
  }

  .lit_main_item {
    grid-template-rows:     19rem auto;
  }
  .lit_main_item_title {
    font-size:               var(--font-size);
  }
}

.is-grid {
  display: none;
}

.is-visible {
  opacity: 1;
}

.rockets{
  transform: translateX(500px);
}

.slideLitMain-enter-active {
  transition: opacity .5s;
}
.slideLitMain-leave-active {
}
.slideLitMain-enter-from {
  opacity: 0;
}
.slideLitMain-leave-to {
}

.d_u_move-enter-active {
  transition: all 1s ease-out;
}

.d_u_move-leave-active {
  transition: all 1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.d_u_move-enter-from,
.d_u_move-leave-to {
  transform: translateY(150px);
  opacity: 0;
}

</style>