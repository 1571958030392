<template>

  <div class="header_lit_container">

    <div id="lit_header_wrapper">
      <div id="lit_header">
        <div>{{ name }}</div>
      </div>
    </div>

  </div>

</template>

<script>

export default {
  name: 'headerLitContainer',
  props: {
    name: String
  }
}

</script>

<style scoped>

.header_lit_container {
  width:                  100%;
  max-width:              100%;
  height:                 auto;
}

#lit_header_wrapper {
  display:                grid;
  grid-template-columns:  1fr;
  max-width:              var(--width-wrapper-small);
  min-height:             120px;
  place-content:          center;
  place-items:            center;
  padding-left:           .5rem;
  padding-right:          .5rem;
  margin:                 3rem auto 3rem auto;
}

@media (min-width: 60rem) {
  #lit_header_wrapper {
    max-width:              var(--width-wrapper-middle);
  }
}
@media (min-width: 75rem) {
  #lit_header_wrapper {
    max-width:              var(--width-wrapper-large);
  }
}

#lit_header {
  width:                  100%;
  min-width:              100%;
  height:                 120px;
  background:             url("../assets/images/lit-header.jpg") no-repeat center / cover;
  border-radius:          22px;
}

#lit_header > div {
  display:                flex;
  height:                 120px;
  align-items:            center;
  justify-content:        center;
  color:                  #eee;
  font-size:              calc(1*var(--font-size-small));
}

@media (min-width: 36rem) {

  #lit_header {
    height:                 150px;
  }

  #lit_header > div {
    height:                 150px;
    font-size:              calc(1.5*var(--font-size-middle));
  }
}

@media (min-width: 60rem) {

  #lit_header {
    height:                 200px;
  }

  #lit_header > div {
    height:                 200px;
    font-size:               calc(1.5*var(--font-size));
  }
}

</style>
